import { AuthLayout, LoginForm } from '@modules/auth';
import { SEO } from '@ui/SEO';
import { promises as fs } from 'fs';
import type { GetStaticPropsContext, NextPage } from 'next';
import path from 'path';
import { useIntl } from 'react-intl';

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  const filePath = path.join(
    process.cwd(),
    `public/translations/${locale}.json`,
  );
  const jsonData = await fs.readFile(filePath, 'utf8');
  const data = JSON.parse(jsonData);

  return {
    props: {
      locale: {
        title: data['seo.login.title'],
        description: data['seo.login.description'],
      },
    },
  };
};

interface Props {
  locale: {
    title: string;
    description: string;
  };
}

const LoginPage: NextPage<Props> = ({ locale }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO title={locale.title} description={locale.description} />
      <AuthLayout
        title={formatMessage({ id: 'auth-layout.login.title' })}
        description={formatMessage({ id: 'auth-layout.login.description' })}
      >
        <LoginForm />
      </AuthLayout>
    </>
  );
};

export default LoginPage;
